import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { L } from '@angular/cdk/keycodes';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  // simpleLogin(email: string, password: string): Observable<UserModel> {
  //   return this.authHttpService.login(email, password);
  // }

  login(email: string, password: string, code: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password, code).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  loginByAdmin(email: string, token): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    this.isLoadingSubject.next(true);
    return this.authHttpService.loginByAdmin(email, token).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    localStorage.clear()
    /* clear the current user value on logout so AuthGuard fails in future */

    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.router.navigate(['/'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.authToken).pipe(
      map((user: UserModel) => {
        if (user) {
          this.currentUserSubject = new BehaviorSubject<UserModel>(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // check for a duplicate email
  checkEmail(email: string): Observable<any> {
    return this.authHttpService.checkEmail(email);
  }

  changePassword(email: string, oldPsw: string, newPsw: string) {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .changePassword(email, oldPsw, newPsw)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  forgotChangePassword(hash: string, psw: string) {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotChangePassword(hash, psw)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  forgotPassword(email: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  viewOrder(id: any): Observable<any>{
    this.isLoadingSubject.next(true);
    return this.authHttpService.getAllOrdes(id).pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
viewOrderByID(id: any): Observable<any> {
  const auth = this.getAuthFromLocalStorage();
  if (!auth || !auth.authToken) {
    return of(undefined);
  }

  this.isLoadingSubject.next(true);
  return this.authHttpService.getorderById(id, auth).pipe(finalize(() => this.isLoadingSubject.next(false)));
}

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
  /* store auth authToken/refreshToken/epiresIn in local storage 
    to keep user logged in between page refreshes */
  if (auth && auth.authToken) {
    localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
    return true;
  }
  return false;
}

  private getAuthFromLocalStorage(): AuthModel {
  try {
    const authData = JSON.parse(
      localStorage.getItem(this.authLocalStorageToken)
    );
    return authData;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

ping(): Observable < AuthModel > {
  return this.authHttpService.ping()
    .pipe(finalize(() => this.isLoadingSubject.next(false)));
}
baseurl() {
  return this.authHttpService.apiurl();
}
ngOnDestroy() {
  this.unsubscribe.forEach((sb) => sb.unsubscribe());
}
}
